/**
 * This file can be replaced during build by using the `fileReplacements` array.
 * `ng build --configuration uat` replaces `environment.ts` with `environment.uat.ts`.
 * The list of file replacements can be found in `angular.json`.
 */

import { Adb2cConfig, ResponseMode, StorageLocation } from '@chubb-auth/types';
export const environment = {
  production: false,
  /* UAT env URL */
  loginUrl: 'https://agentview-uat.chubb.com/',
  siteKey: '6Le3O9kZAAAAAGo5Tm2xoArfnY0r032owfZZaWBK',
  wildCardRoute: 'https://agentview-uat.chubb.com/',
  hiddenURL: 'https://agentview-uat.chubb.com/dashboard',
  getAccessBaseUrl: 'https://extmga01.chubb.com/atChubb3Angular',
  backend: 'https://secure-proxy-uat.chubb.com/api',
  baseUrl: 'https://ps-secure.chubb.com',
  carouselImagePath: 'https://www-ps.chubb.com/atChubbContent/Contents/Images/',
  pmsg: 'https://ps-secure.chubb.com/atChubb/en_US/pmsg.txt',
  clientPortalLogin: 'https://prsclientview-uat.chubb.com',
  uri: {
    userProfile: '/user-profile',
    isEmailExist: '/is-email-exist',
    sendUserId: '/send-userid',
    sendPasswordLink: '/send-password-link',
    setPassword: '/edit-pass-by-ui',
    updateUserProfile: '/edit-basic-info',
    requestAccess: '/self-request',
    accessTypeList: '/access-type-list',
    getNews: '/get-news',
    emailVerificationRequest: '/verification-request',
    emailVerificationCheck: '/verification-check',
    editEmail: '/edit-email',
    fallbackMessageUrl: '/get-fallback-message',
    // GAM-2148: This API is not required for angular app
    /* currentOutage: {
      apiUrl:
        'https://napprd.chubbdigital.com/digital.servicing.entagentprelogin/login/outageinfo',
      'Ocp-Apim-Subscription-Key': 'f85da01bea334983a9513e291066351e',
      fallbackMessageTime: 30,
    }, */
    // GAM-2148: This API is not required for angular app
    // getCarousel: '/get-carousel',
    getCriticalMsg: '/get-criticalmsg',
    stepValue: '/step-value',
  },
  adb2cConfig: <Adb2cConfig>{
    stateKey: 'AT_CHUBB_LOGIN_AUTH',
    tenant: 'auth.uat.chubb.com',
    policy: 'B2C_1A_AGENTPORTAL_FORGOTPASSWORD',
    stateLocation: StorageLocation.Session,
    clientId: 'c1292213-ec81-4379-8bd2-874571376fd5',
    redirectUri: 'https://secure-uat.chubb.com/reset-password-confirmation',
    scopes: [
      'https://uatb2c.chubbdigital.com/AtChubbAgentPortalAPI/read',
      'https://uatb2c.chubbdigital.com/AtChubbAgentPortalAPI/write',
    ],
    responseMode: ResponseMode.Query,
  },
  /** GAM-1968: Password Expired (By System) Flow requires 
    same "Forgot Password" Policy but with different "redirectUri"
  */
  adb2cConfigPwdExpiredBySystem: <Adb2cConfig>{
    stateKey: 'AT_CHUBB_LOGIN_AUTH',
    tenant: 'auth.uat.chubb.com',
    policy: 'B2C_1A_AGENTPORTAL_FORGOTPASSWORD',
    stateLocation: StorageLocation.Session,
    clientId: 'c1292213-ec81-4379-8bd2-874571376fd5',
    redirectUri: 'https://secure-uat.chubb.com/update-password-confirmation',
    scopes: [
      'https://uatb2c.chubbdigital.com/AtChubbAgentPortalAPI/read',
      'https://uatb2c.chubbdigital.com/AtChubbAgentPortalAPI/write',
    ],
    responseMode: ResponseMode.Query,
  },
  // GAM-2148: This API is not required for angular app
  /* a2aTokenAPI: {
    apiURL:
      'https://napprd.chubbdigital.com/enterprise.operations.authorization/?Identity=AAD',
    App_ID: '7b762c79-28ec-4f01-881c-bea34bf645c8',
    App_Key: 'ss48Q~wlu5OlNVsVI6gcu7ERu4SuzUAyXV11Ba6Z',
    ApiVersion: '1',
    Resource: '53e9eb16-6d01-412b-a4ad-091d7dc78c27',
  }, */
};
