import {
  UserDetails,
  UserDetailsRawResponseModel,
} from '../../models/user-details.model';
import { get } from 'lodash-es';

export function UserDetailsTransformation(
  data: UserDetailsRawResponseModel
): UserDetails {
  const source = data.getAccessAAMUserProfileListReply.getAccessAAMUserProfileListOutputs;
  // const source = data;
  return {
    userName: get(source[0], 'externalUserId'),
    emailAddress: get(source[0], 'emailAddress'),
    firstName: get(source[0], 'firstName'),
    lastName: get(source[0], 'lastName'),
    stepValue: get(source[0], 'stepValue'),
    phoneNumber: get(source[0], 'phoneNumber'),
    modifiedBy: get(source[0], 'modifiedBy'),
    status: get(source[0], 'status'),
  };
}
